
/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
    data() {
        return {
            bgImages: [
              'bg1.jpg',
              'bg10.jpg',
              'bg11.jpg',
              'bg2.jpg',
              'bg3.jpg',
              'bg4.jpg',
              'bg5.jpg',
              'bg6.jpg',
              'bg7.jpg',
              'bg8.jpg',
              'bg9.jpg'
            ]
        };
    },
    computed: {
        imgSrc() {
            return `${window.location.origin}/assets/images/john.gif`;
        },
        bgImgSrc() {
            let rand = Math.floor(Math.random() * (this.bgImages.length - 1)) + 0;
            return `${window.location.origin}/assets/images/404backgrounds/${this.bgImages[rand]}`;
        }
    }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class Error404View extends Vue {}
